import { useStaticQuery, graphql } from "gatsby"
import T from "types/index";

const query = () : T.Charles.CategoryRecord[] => {
  const { allAirtableCategories: { nodes } } = useStaticQuery(graphql`
    query PathoSurFooterQuery {
      allAirtableCategories(sort: { order: ASC, fields: data___order }) {
        nodes {
          id
          data {
            slug
            name
            home_title
            home_description
            color
            home_display_on_top
            display_on_home
            consultation_direct_link
            footer_anchor
            color_icon { ...AirtableImagesConstrainedFields }
            home_image { ...AirtableImagesConstrainedFields }
            home_image_md { ...AirtableImagesConstrainedFields }
            pathologies {
              id
              data {
                slug
                breadcrumb_label
                metadata {
                  data {
                    no_index
                  }
                }
              }
            }
            home_pathologies {
              id
              data {
                slug
                breadcrumb_label
                metadata {
                  data {
                    no_index
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return nodes
}

export const shouldIndexPathology = (pathology: T.Charles.PathologyRecord) => {
  const metadata = pathology.data.metadata[0]
  const noIndex = metadata?.data.no_index == true
  return !noIndex
}

export const useAirtableCategories = (onlyIndexed: boolean): T.Charles.CategoryRecord[] => {
  const records = query()

  if(onlyIndexed){
    const categoriesWithoutUnindexedPathologies = records.map((category) => {
      const clonedCategory = structuredClone(category)
      const pathologies = clonedCategory.data.pathologies
      const homePathologies = clonedCategory.data.home_pathologies
      if (pathologies && homePathologies) {
        clonedCategory.data.pathologies = category.data.pathologies.filter(shouldIndexPathology)
        clonedCategory.data.home_pathologies = category.data.home_pathologies.filter(shouldIndexPathology)
      }
      return clonedCategory
    })
    return categoriesWithoutUnindexedPathologies
  }
  else {
    return records;
  }
}

export default () => useAirtableCategories(false)