import React from 'react';
import { graphql } from 'gatsby';
import { Layout } from 'components/common';
import Segment from "utils/segment";
import CharlesMeta from 'components/common/meta/CharlesMeta';
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import TopSectionHub from 'templates/Pathologies/common/TopSectionHub';
import Cta from 'components/common/Cta';
import T from "types/index";

import "./styles.sass";
import CategoriesList from './CategoriesList';
import SurFooter from 'templates/Pathologies/common/SurFooter';
import { pathologiesGrandMotherBreadcrumbs } from 'templates/Blog/common/Breadcrumb';

// TODO LUCAS WHY NO BREADCRUMB ?
type Props = {
  data: {
    templateDataRecord: T.Charles.MotherTemplateDataRecord
    metadataRecord: T.Charles.MetadataRecord
    allAirtableCategories: {
      nodes: T.Charles.CategoryRecord[]
    }
  },
}
const GrandMother = ({ data }: Props) => {
  const templateData: T.Charles.MotherTemplateData = data.templateDataRecord.data
  const metadata: T.Charles.Metadata = data.metadataRecord.data
  const categoriesRecords: T.Charles.CategoryRecord[] = data.allAirtableCategories.nodes
  const categoriesWithPathologies: T.Charles.CategoryRecord[] = categoriesRecords.filter((c) => !!c.data.pathologies)
  const cta = <Cta
    text={templateData.cta_label}
    className="new-primary-cta pathology-cta"
    path={templateData.start_slug}
  />

  return (
    <Layout
      showLinksInFooter={false}
      footerCtaLabel={"Consultez un médecin"}
    >
      <CharlesMeta
        title={metadata.title}
        description={metadata.meta_description}
        segmentCategory={Segment.CATEGORIES.PATHOLOGIES}
      />
      <StructuredWebPage
        title={templateData.h1.internal.content}
        description={metadata.meta_description}
      />
      <div className="pathology-template" id="gm-template">
        <TopSectionHub
          h1={templateData.h1}
          subtitle={templateData.subtitle}
          cta_label={templateData.cta_label}
          start_slug={templateData.start_slug}
          main_image={templateData.main_image}
          reinsurance_items={templateData.reinsurance_items}
          cta={cta}
        />
        <CategoriesList
          categoriesRecords={categoriesWithPathologies}
          pathologies_title={templateData.pathologies_title}
          pathologies_subtitle={templateData.pathologies_subtitle}
        />
        <SurFooter
          breadcrumbLinks={pathologiesGrandMotherBreadcrumbs()}
          className="pathologies-mother-surfooter"/>
      </div>
    </Layout>
  )
};

export default GrandMother;

// TODO LUCAS TAKE ONLY PATHOLOGIES FOR WHICH WE CREATE PAGES
// We could use createNodeField to avoid duplicating the logic of whether or not we should build a patho page
export const pageQuery = graphql`
  {
    allAirtableCategories(sort: {order: ASC, fields: data___order}) {
      nodes {
        id
        data {
          slug
          name
          presentation
          color_icon { ...AirtableImagesConstrainedFields }
          pathologies {
            id
            data {
              slug
              breadcrumb_label
              status
              environment
              metadata {
                data {
                  no_index
                }
              }
            }
          }
        }
      }
    }
    metadataRecord: airtableMetadata(data: { code: { eq: "grand-mother_do_not_modify" }}) {
      ...AirtableMetadataFields
    }
    templateDataRecord: airtableMotherTemplateData(data: { code_name: { eq: "grand-mother_do_not_modify" }}) {
      ...AirtableMotherTemplateDataFields
    }
  }
`;
