import React from "react";
import cx from "classnames";
import "./styles.sass";
import Container from "react-bootstrap/Container";
import { AirtableImages } from "components/common/utils/AirtableImage";
import { AirtableMarkdown } from "components/common/utils/AirtableMarkdown";
import T from "types/index";

export default (props: {
  reinsurance_items: T.Charles.ItemRecord[];
  className: string;
}) => {
  const { reinsurance_items, className } = props;

  return (
    <div className={cx("reinsurance-wrapper", className, "d-xl-none")}>
      {reinsurance_items.map((item: T.Charles.ItemRecord, i: number) => (
        <div className="reinsurance-div" key={`reinsuranceList-${i}`}>
          <AirtableImages images={item.data.image} />
          <AirtableMarkdown markdown={item.data.title} />
        </div>
      ))}
    </div>
  );
};

export const NewReinsurance = (props: {
  reinsurance_items: T.Charles.ItemRecord[];
  className?: string;
}) => {
  const { reinsurance_items, className } = props;
  return (
    <div className={cx("reinsurance-section", "d-none d-xl-flex")}>
      <Container>
        <div className="reinsurance-section-container">
          {reinsurance_items.map((item: T.Charles.ItemRecord, i: number) => (
            <div className="reinsurance-item" key={`reinsuranceList-${i}`}>
              <AirtableImages
                className="reinsurance-item-image"
                images={item.data.image}
              />
              <div className="reinsurance-item-text"><AirtableMarkdown markdown={item.data.title} /></div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};
