import React from "react";
import cx from "classnames";
import T from "types/index";
import "./styles.sass";
import { isNil } from "utils/lodashExtracts";

const AirtableMarkdown = ({
  markdown,
  className,
  raw = false,
}: {
  markdown: T.Standard.Markdown;
  raw?: boolean;
  className?: string;
}) => (
  <div className={className}>
    {isNil(markdown) ? null : raw ? (
      markdown.childMarkdownRemark.rawMarkdownBody
    ) : (
      <div
        className="airtable-markdown-content"
        dangerouslySetInnerHTML={{ __html: markdown.childMarkdownRemark.html }}
      />
    )}
  </div>
);

const markdownFromString = (input: string) : T.Standard.Markdown => {
  return {
    childMarkdownRemark: {
      html: `<span>${input}</span>`,
      rawMarkdownBody: input
    }
  }
}

export { AirtableMarkdown, markdownFromString };
