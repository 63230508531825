import React from "react";
import cx from "classnames";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Section from "components/common/Section";
import Reinsurance, {
  NewReinsurance,
} from "templates/Pathologies/common/Reinsurance";
import Cta from "components/common/Cta";
import "./styles.sass";
import T from "types/index";
import { AirtableImages } from "components/common/utils/AirtableImage";
import { AirtableMarkdown } from "components/common/utils/AirtableMarkdown";

type Props = {
  h1: T.Standard.Markdown;
  subtitle: T.Standard.Markdown;
  cta_label: string;
  start_slug: string;
  main_image: T.Charles.ImageRecord[];
  reinsurance_items: T.Charles.ItemRecord[];
  cta: React.ReactNode;
  headingClassName?: string;
  imageClassName?: string;
};

const TopSectionHub = (props: Props) => (
  <Section
    id="pathology-head"
    className={cx("pathology-heading-section", props.headingClassName)}
  >
    <div className="pathology-top-section-background-wrapper">
      <div className="content-part-pathology-testimony">
        <Container className="pathology-top-container">
          <Row className="justify-content-md-center">
            <Col xs={12} xl={7}>
              <>
                <h1 className="pathology-h1">
                  {props.h1.childMarkdownRemark.rawMarkdownBody}
                </h1>
                <div className="pathology-subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle.childMarkdownRemark.html }} />
                {props.cta || (
                  <Cta
                    text={props.cta_label}
                    className="new-primary-cta pathology-cta"
                    path={props.start_slug}
                  />
                )}
                <Reinsurance
                  className="reinsurance-wrapper-pathology"
                  reinsurance_items={props.reinsurance_items}
                />
              </>
            </Col>
            <Col
              xs={12}
              md={9}
              xl={5}
              className={cx("pathology-image-wrapper", props.imageClassName)}
            >
              <AirtableImages
                images={props.main_image}
                className="pathology-image-top-section"
              />
            </Col>
          </Row>
        </Container>
        <NewReinsurance reinsurance_items={props.reinsurance_items} />
      </div>
    </div>
  </Section>
);

export default TopSectionHub;
