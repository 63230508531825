import React from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "templates/Blog/common/Breadcrumb";
import FooterAccordion from "components/common/FooterAccordion";
import LinkOrAOrText from "components/common/links/LinkOrAOrText";
import T from "types/index";
import cx from "classnames";
import { isEmpty, isNil } from "utils/lodashExtracts";
import "./styles.sass";

type Props = {
  breadcrumbLinks: T.Standard.BreadcrumbLink[];
  footerContent?: T.Standard.FooterAccordionContent;
  className?: string;
};

const SurFooter = ({ breadcrumbLinks, className, footerContent }: Props) => (
  <div className={cx("sur-footer", className)}>
    <Container className="sur-footer-container">
      <div className="sur-footer-breadcrumb">
        <Breadcrumb links={breadcrumbLinks} />
      </div>
      {(footerContent != null) && (
        <div className="sur-footer-content">
          <div className="text-left h2 mb-2">
            <LinkOrAOrText
              anchor={footerContent.title}
              to={footerContent.titleLink}
            />
          </div>
          <div className="sur-footer-content-pathologies-wrapper">
            <FooterAccordion
              sections={footerContent.sections}
              whiteVersion={false}
              column={true}
            />
          </div>
        </div>
      )}
    </Container>
  </div>
);

export default SurFooter;
